<template >
  <v-container fluid>
    <span>
      <span class="d-flex flex-row">
        <h1>Reporting</h1>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="initialLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </span>
      <h2 class="mt-5">General Reports</h2>
      <v-btn class="mr-2 my-1" v-for="(route, i) of reportingRoutes" :key="i" :to="route.path">{{route.name}}</v-btn>
      <v-btn v-for="(btn, b) of customReports" :key="b+'custom'" class="mr-2 my-1" :color="(btn.metadata && btn.metadata.color)?btn.metadata.color:''" @click="$router.push(`/reporting/${btn.route}`)"><v-icon v-if="(btn.metadata && btn.metadata.icon)">{{btn.metadata.icon}}</v-icon>{{(btn.metadata && btn.metadata.label)?btn.metadata.label:'No Label'}}</v-btn>
      <h2 class="mt-5">Print Only Reports</h2>      
      <div v-if="getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_REPORTING')" style="width: 100%;">
        <dynamicButtonContainer :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_REPORTING')}`" :data="{id1: $route.params.id}" style="width: 100%;"/>
      </div>
    </span>
<!--    <v-row v-if="getGlobalValue('showSummaryOnReportIndex')==='true'">-->
<!--      <v-col>-->
<!--        <h2>Summary</h2>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row v-if="getGlobalValue('showSummaryOnReportIndex')==='true'">-->
<!--      <v-col cols="4" v-if="sales.sales">-->
<!--        <v-card outlined>-->
<!--          <v-card-text>-->
<!--            <h3>Total Sales</h3>-->
<!--            <span class="text-h3">{{utils.formatCurrency(sales.sales)}}</span>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col cols="4" v-if="sales.received">-->
<!--        <v-card outlined>-->
<!--          <v-card-text>-->
<!--            <h3>Total Sales Received</h3>-->
<!--            <span class="text-h3">{{utils.formatCurrency(sales.received)}}</span>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col cols="4" v-if="sales.outstanding">-->
<!--        <v-card outlined>-->
<!--          <v-card-text>-->
<!--            <h3>Total Sales Outstanding</h3>-->
<!--            <span class="text-h3">{{utils.formatCurrency(sales.outstanding)}}</span>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col cols="4" v-if="getGlobalValue('showCommissionsReports')==='true' &&commissions.commission">-->
<!--        <v-card outlined>-->
<!--          <v-card-text>-->
<!--            <h3>Total Commissions</h3>-->
<!--            <span class="text-h3">{{utils.formatCurrency(commissions.commission)}}</span>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col cols="4" v-if="commissions.paid">-->
<!--        <v-card outlined>-->
<!--          <v-card-text>-->
<!--            <h3>Total Paid Commissions</h3>-->
<!--            <span class="text-h3">{{utils.formatCurrency(commissions.paid)}}</span>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--      <v-col cols="4" v-if="commissions.outstanding">-->
<!--        <v-card outlined>-->
<!--          <v-card-text>-->
<!--            <h3>Total Outstanding Commission</h3>-->
<!--            <span class="text-h3">{{utils.formatCurrency(commissions.outstanding)}}</span>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import reportingRoutes from "../../router/reporting";
import utils from "./../../plugins/helpers"
import axios from "axios";
import dynamicButtonContainer from './../../components/dynamicButtonContainer.vue';
export default {
  components: {
    dynamicButtonContainer
  },
  name: 'Home',
  data () {
    return {
      utils: utils,

      initialLoader: true,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      sales: {},
      commissions: {},

      reportingRoutes: [],
      excludeRoutes: [],

      customReports: [],
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint', 'getGlobalValue', 'isAllowed'])
  },
  async mounted(){
    try {
      this.excludeRoutes = this.getGlobalValue('excludedReportRoutes')?.split(",") || [];

      let temp = reportingRoutes.filter(x=>x.name!=="reports" && x.name!=="CustomReport" && !this.excludeRoutes.find(y => x.name.toLowerCase().includes(y.toLowerCase())) && !this.excludeRoutes.find(y => x.path.toLowerCase().includes(y.toLowerCase())));

      let res = await axios.get(`${this.getEndpoint}/api/custom/getCustomReports`)
      if(res.data.error) throw res.data.error
      this.customReports = res.data.data

      temp = temp.filter(x => {
        if(!x.permission) return true;

        if(this.isAllowed(x.permission[0], x.permission[1])) return true;
        return false;
      });

      this.reportingRoutes = temp;

      // let sales = await axios.get(`${this.getEndpoint}/api/reporting/summary/sales`)
      // if(sales.data.error) throw sales.data.error
      // this.sales = sales.data.data;
      //
      // if(this.getGlobalValue('showCommissionsReports')==='true'){
      //   let commissions = await axios.get(`${this.getEndpoint}/api/reporting/summary/commissions`)
      //   if(commissions.data.error) throw commissions.data.error
      //   this.commissions = commissions.data.data;
      // }

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
